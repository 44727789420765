<template>
  <CRow>
    <CCol :xs="12">
      <CCard class="mb-4">
        <CCardHeader> <strong>Review Reports</strong> </CCardHeader>
        <CRow
          class="g-3 align-self-end"
          style="margin-right: 5%; margin-top: 1%"
        >
          <CCol xs="auto" class="align-self-end">
            <CFormInput
              type="text"
              v-model="search"
              @change="searchData"
              placeholder="Search"
            />
          </CCol>
        </CRow>
        <CCardBody>
          <CRow class="justify-content-start">
            <!-- <CCol xs="1">
              <CFormSelect style size="sm">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
                <option value="60">60</option>
                <option value="70">70</option>
                <option value="80">80</option>
                <option value="90">90</option>
                <option value="100">100</option>
              </CFormSelect>
            </CCol> -->
          </CRow>
          <CTable hover>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col">No</CTableHeaderCell>
                <CTableHeaderCell scope="col">Product</CTableHeaderCell>
                <CTableHeaderCell scope="col">Category</CTableHeaderCell>
                <CTableHeaderCell scope="col">Reasons</CTableHeaderCell>
                <CTableHeaderCell scope="col">Posted By</CTableHeaderCell>
                <CTableHeaderCell scope="col"
                  >Go To Review Detail</CTableHeaderCell
                >
              </CTableRow>
            </CTableHead>
            <CTableBody
              v-for="(r, idx) in $store.state.reviewReports.reviewReports"
              :key="idx"
            >
              <CTableRow>
                <CTableHeaderCell scope="row">{{
                  idx + 1 + (currentPage - 1) * perPage
                }}</CTableHeaderCell>
                <CTableDataCell>{{ r.productName }}</CTableDataCell>
                <CTableDataCell>{{ r.categoryName }}</CTableDataCell>
                <CTableDataCell>{{ r.reasonName }}</CTableDataCell>
                <CTableDataCell>{{ r.name }}</CTableDataCell>

                <CTableDataCell>
                  <router-link :to="`/pages/reviewdetail/${r.reviewId}`">
                    <CIcon name="cil-search"
                  /></router-link>
                  <!-- <CButton
                    size="sm"
                    color="primary"
                    @click="
                      () => {
                        this.rou
                      }
                    "
                  >
                    <CIcon name="cil-search" />
                  </CButton> -->
                </CTableDataCell>
              </CTableRow>
            </CTableBody>
          </CTable>
          <div class="overflow-auto">
            <b-pagination
              v-model="currentPage"
              :total-rows="$store.state.review.totalRecord"
              :per-page="perPage"
              aria-controls="my-table"
              :change="checkPage(currentPage)"
            ></b-pagination>
          </div>
          <!-- <CPagination align="end" aria-label="Page navigation example">
            <CPaginationItem disabled>Previous</CPaginationItem>
            <CPaginationItem href="#">1</CPaginationItem>
            <CPaginationItem href="#">2</CPaginationItem>
            <CPaginationItem href="#">3</CPaginationItem>
            <CPaginationItem href="#">Next</CPaginationItem>
          </CPagination> -->
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import review from './../../apis/review'
import DOMPurify from 'dompurify'
export default {
  name: 'Reviews',
  data() {
    return {
      reviewId: 0,
      visibleStaticBackdrop: false,
      isDisable: 0,
      isLoading: false,
      currentPage: 1,
      perPage: 5,
      totalRows: 1,
      search: '',
    }
  },
  mounted() {
    setTimeout(() => {
      this.$store.dispatch('getAllReviewReports', {
        skip: this.skip,
        take: this.take,
        search: this.search,
      })
    }, 500)
  },
  methods: {
    checkPage(page) {
      setTimeout(() => {
        this.$store.dispatch('getAllReviewReports', {
          skip: this.skip,
          take: this.take,
          search: this.search,
        })
      }, 500)
      //   this.$store.dispatch('getAllReviewReports', {
      //     skip: this.skip,
      //     take: this.take,
      //     search: this.search,
      //   })
      console.log(page)
    },
    sanitizedText(text) {
      return DOMPurify.sanitize(text)
    },
    searchData() {
      setTimeout(() => {
        this.currentPage = 1
        this.skip = 0
        this.take = this.perPage
        this.checkPage(1)
      }, 500)
    },
  },
  computed: {
    skip: function () {
      return (this.currentPage - 1) * this.perPage
    },
    take: function () {
      return this.perPage
    },
  },
}
</script>
